<template>
  <a-modal :visible="showModal" :centered="true" :width="dialogWidth" :destroyOnClose="true" @cancel="handleCancel">
    <template v-slot:title>
      <span style="font-size:13px">{{ title }}</span>
    </template>
    <template v-slot:closeIcon>
      <a-icon type="close"
              style="color:#3C4A54;font-size: 12px;margin-top:14px;margin-right:14px;float:right;"/>
    </template>
    <template v-slot:footer>
      <a-button-group style="margin-top:10px;width:100%;padding-left:75%;">
        <a-button v-show="!isView" style="margin-right:10px;" type="primary" @click="save">保存
        </a-button>
        <a-button style="margin-right:10px;" type="primary" @click="close">关闭</a-button>
        <a-button style="margin-right:10px;" type="primary" @click="uploadReport">上传</a-button>
        <a-button type="primary" @click="exportReport">导出</a-button>
      </a-button-group>
    </template>
    <div class="main" ref="reportPDF">
     <a-spin :spinning="loading" tip="数据获取中，请稍候......" >
      <div class="head"></div>
      <div class="subtitle">
        中国石油天然气股份有限公司石油化工研究院<br/>
        电镜图像报告单
      </div>
      <a-form-model ref="eleReportForm" :model="oldData"  :labelCol="{span:8}" :wrapperCol="{span:16}" style="height:100%;margin:5px;">
          <a-row style="border-bottom: 1px solid #EEEEEE;padding-top:10px;padding-bottom: 8px;color:black;">
            <a-col :span="8">
            <span style="padding-left:30px;display:inline-block;">
                报告单号：{{ oldData.report_id }}
            </span>
            </a-col>
            <a-col :span="8">
            </a-col>
            <a-col :span="8">
            <span v-if="isView" style="padding-left:60px;display:inline-block;">
                拍摄日期：{{ oldData.photo_date }}
            </span>
              <a-form-model-item v-else label="拍摄日期：" prop="photo_date">
                <a-date-picker v-model="oldData.photo_date" placeholder="" show-time
                                 valueFormat="YYYY-MM-DD HH:mm:ss"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row style="padding-top: 10px;">
            <a-col :span="8">
              <a-form-model-item label="送样人员：" prop="operator">
                <a-input v-model="fullname" :disabled="true" placeholder="" readonly/>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="催化剂类型：" prop="catalyst_type">
                <a-input v-model="oldData.catalyst_type" :disabled="true" placeholder=""/>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="样品编号：" prop="sample_number">
                <a-input v-model="oldData.sample_number" :disabled="true" placeholder=""/>
              </a-form-model-item>
            </a-col>
          </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="电压：" prop="voltage">
              <a-input v-model="oldData.voltage" :disabled="isView" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="Spot Size：" prop="spot_size">
              <a-input v-model="oldData.spot_size" :disabled="isView" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="聚光镜光镧：" prop="condenser_lan">
              <a-input v-model="oldData.condenser_lan" :disabled="isView" placeholder=""/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="物镜光镧：" prop="objective_lense_lan">
              <a-input v-model="oldData.objective_lense_lan" :disabled="isView" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="AC：" prop="ac">
              <a-input v-model="oldData.ac" :disabled="isView" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="放大倍数：" prop="magnification">
              <a-input v-model="oldData.magnification" :disabled="isView" placeholder=""/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="this.sampleType==1">
          <a-col :span="8">
            <a-form-model-item label="平均长度：" prop="average_length">
              <a-input v-model="oldData.average_length" :disabled="true" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="平均个数：" prop="average_number">
              <a-input v-model="oldData.average_number" :disabled="true" placeholder=""/>
            </a-form-model-item>
          </a-col>
          </a-row>
        <a-row v-else-if="this.sampleType==2">
          <a-col :span="8">
            <a-form-model-item label="平均孔隙率：" prop="hole">
              <a-input v-model="oldData.hole" :disabled="true" placeholder=""/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-else>
          <a-col :span="8">
            <a-form-model-item label="平均长度：" prop="average_length">
              <a-input v-model="oldData.average_length" :disabled="true" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="平均堆叠层数：" prop="average_layer">
              <a-input v-model="oldData.average_layer" :disabled="true" placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="平均个数：" prop="average_number">
              <a-input v-model="oldData.average_number" :disabled="true" placeholder=""/>
            </a-form-model-item>
          </a-col>
        </a-row>
          <a-row style="margin-top:5px;">
            <a-col :span="8">
              <CatalystChart v-if="chartLengthOption" ref="eleChartLength" :chartWidth="chartWidth" :chartOption="chartLengthOption"/>
            </a-col>
            <a-col :span="8">
              <CatalystChart v-if="chartLayerOption" ref="eleChartLayer" :chartWidth="chartWidth" :chartOption="chartLayerOption"/>
            </a-col>
            <a-col :span="8">
              <CatalystChart v-if="chartNumberOption" ref="eleChartNumber" :chartWidth="chartWidth" :chartOption="chartNumberOption"/>
            </a-col>
          </a-row>
          <a-row style="color:black;">
            <a-col :span="8">
              <a-form-model-item label="样品名称：">
                <a-input @blur="updateSerialName" v-model="oldData.sample_name" placeholder=""/>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="样品类型：">
                <a-input v-model="oldData.sample_type" :disabled="true"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="图像分析：" prop="average_length">
                <a-input v-model="oldData.image_analysis" :disabled="true"/>
              </a-form-model-item>
            </a-col>
          </a-row>
      </a-form-model>
     </a-spin>
    </div>
  </a-modal>
</template>

<script>
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import moment from 'moment';
import { Modal } from 'ant-design-vue';

import {editReport,uploadReport,getReport,} from "../utils/data-manager";
import CatalystChart from './CatalystChart'
export default {
  name: "ReportDialog",
  components: {
    CatalystChart
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "报告管理"
    },
    id: {
      type: Number,
      default: null
    },
    sampleType:{
      type:Number,
      default:0
    },
    reportView: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading:false,
      chartLengthOption: null,
      chartLayerOption: null,
      chartNumberOption: null,
      chartWidth: 360,
      dialogWidth: 1100,
      oldData: {},
      isView:true
    }
  },
  watch:{
    async  showModal(value) {
      if(value){
        this.loading=true;
        this.oldData= await getReport(this.id);
        this.loading=false;
      }else{
        this.oldData= {};
      }
      if(this.sampleType==0){
        this.createChartOption();
      }
    },
    reportView(value){
      this.isView=value;
    }
  },
  computed:{
    fullname(){
      return (this.oldData.department?this.oldData.department+'-':'')+this.oldData.operator;
    }
  },
  methods: {
    close() {
      this.handleCancel();
    },
    handleCancel() {
      this.$parent.closeReport();
    },
    async save(){
      if(this.id==null){
        Modal.info({
          title:"提示信息",
          content:"要保存的报告不存在！",
          centered:true,
        });
      }else{
        let data=await editReport(this.oldData);
        if(data){
          if(data.flag){
            this.close();
            this.$parent.search();
          }else{
            let infoTitle="操作失败";
            Modal.info({
              title:infoTitle,
              content: data.msg,
              centered:true
            });
          }
        }
      }
    },
    exportReport(){
      let page=this.$refs.reportPDF;
      html2canvas(page).then(function(canvas){
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = 592.28 / contentWidth * contentHeight;
        let pageData = canvas.toDataURL('image/jpeg', 1.0);
        let PDF = new JsPDF('', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        let reportFileName="报告单"+new moment().format("yyyyMMDDhhmmss")+".pdf";
        PDF.save(reportFileName);
      });
    },
   async uploadReport(){
     let data=await uploadReport(this.id);
     if(data){
       let infoTitle="操作失败";
       if(data.flag){
         infoTitle="操作成功";
       }
       Modal.info({
         title:infoTitle,
         content: data.msg,
         centered:true
       });
     }
    },
    updateOptionMap(datamap,newkey){
      let oldkey=Array.from(datamap.keys())[0]
      let value=datamap.get(oldkey);
      datamap.delete(oldkey);
      datamap.set(newkey,value);
    },
    updateSerialName(e){
      if(this.sampleType!=0)
        return;
      let newkey=e.target.value;
      this.updateOptionMap(this.chartNumberOption.dataMap,newkey);
      this.updateOptionMap(this.chartLayerOption.dataMap,newkey);
      this.updateOptionMap(this.chartLengthOption.dataMap,newkey);
      this.$refs.eleChartNumber.refreshChart(this.chartNumberOption.dataMap);
      this.$refs.eleChartLayer.refreshChart(this.chartLayerOption.dataMap);
      this.$refs.eleChartLength.refreshChart(this.chartLengthOption.dataMap);
  },
    createChartOption(){
      if (JSON.stringify(this.oldData)!=="{}") {
        if (this.chartLengthOption == null) {
          this.chartLengthOption = new Object();
          this.chartLengthOption.isShowSelLabel=false;
          this.chartLengthOption.model = 0;
          this.chartLengthOption.title = "催化剂上MOS₂片晶的长度分布";

          this.chartLengthOption.reportId=this.id;
          this.chartLengthOption.dataType=0;
          this.chartLengthOption.isSaveImage=true;

          this.chartLengthOption.yTitle = "%";
          this.chartLengthOption.yPercent = false;
          this.chartLengthOption.xTitle = "MOS₂片晶的长度/nm";
          this.chartLengthOption.chartType = "bar";
          this.chartLengthOption.xNames = ["<3","3~4", "4~5", "5~6", ">6"];
          this.chartLengthOption.dataMap = new Map();
          this.chartLengthOption.dataMap.set(this.oldData.sample_name, this.oldData.arrayLength);
        }
        if (this.chartLayerOption == null) {
          this.chartLayerOption = new Object();
          this.chartLayerOption.isShowSelLabel=false;
          this.chartLayerOption.model = 0;
          this.chartLayerOption.title = "催化剂上MOS₂片晶的层数分布";

          this.chartLayerOption.dataType=1;
          this.chartLayerOption.reportId=this.id;
          this.chartLayerOption.isSaveImage=true;

          this.chartLayerOption.yTitle = "%";
          this.chartLayerOption.yPercent = false;
          this.chartLayerOption.xTitle = "MOS₂片晶层数";
          this.chartLayerOption.chartType = "line";
          this.chartLayerOption.xNames = ["1", "2", "3", "4", "5", "6", "7"];
          this.chartLayerOption.dataMap = new Map();
          this.chartLayerOption.dataMap.set(this.oldData.sample_name, this.oldData.arrayLayer);
        }
        if (this.chartNumberOption == null) {
          this.chartNumberOption = new Object();
          this.chartNumberOption.isShowSelLabel=false;
          this.chartNumberOption.model = 0;
          this.chartNumberOption.title = "催化剂上MOS₂片晶的个数";

          this.chartNumberOption.dataType=2;
          this.chartNumberOption.reportId=this.id;
          this.chartNumberOption.isSaveImage=true;

          this.chartNumberOption.yTitle = "MOS₂片晶的个数/1000nm²";
          this.chartNumberOption.yPercent = false;
          this.chartNumberOption.xTitle = "MOS₂片晶层数";
          this.chartNumberOption.chartType = "line";
          this.chartNumberOption.xNames = ["1", "2", "3", "4", "5", "6", "7"];
          this.chartNumberOption.dataMap = new Map();
          this.chartNumberOption.dataMap.set(this.oldData.sample_name, this.oldData.arrayNumber);
        }
      }else{
        this.chartLengthOption=null;
        this.chartLayerOption=null;
        this.chartNumberOption=null;
      }
    }
  }
}
</script>

<style scoped>
.main {
  background: white;
}

.subtitle {
  font-weight: bolder;
  font-size: 16px;
  text-align: center;
  color: black;
}

.head {
  height: 61px;
  background: url("../assets/banner.png") no-repeat;
  background-size: 224px 61px
}

/deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .ant-modal-body {
  padding: 0px;
}

/deep/ .ant-modal-body input {
  /* border: none; */
  border-radius: 0px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .ant-modal-body input:focus {
  /* border: none; */
  border-radius: 0px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .ant-row {
  margin: 0px;
  padding: 0px;
}
.ant-input[disabled]{
  /* background: white; */
  color:black;
}
.ant-input[disabled]:hover{
  cursor:default;
  border-bottom: 1px solid #EEEEEE;
}
/*/deep/ .ant-input:focus{*/
/*  border-bottom: 1px solid black;*/
/*}*/
::v-deep .main .ant-select, .ant-input{
    width: 220px!important;
}
</style>
