<template>
  <a-modal :visible="showModal" :centered="true" :width="dialogWidth" :destroyOnClose="true" @cancel="handleCancel">
    <template v-slot:title>
      <span style="font-size:13px">{{ title }}</span>
    </template>
    <template v-slot:closeIcon style="padding: 10px;">
      <a-icon type="close"
              style="color:#3C4A54;font-size: 12px;margin-top:14px;margin-right:14px;float:right;"/>
    </template>
    <template v-slot:footer style="padding: 10px;">
      <a-button-group style="margin-top:10px;width:100%;padding-left:85%;">
        <a-button style="margin-right:10px;" type="primary" @click="save" :disabled="!showImage">保存</a-button>
        <a-button style="" type="primary" @click="close">关闭</a-button>
      </a-button-group>
    </template>
    <div class="main" style="padding: 10px;">
     <a-spin :spinning="loading" tip="数据获取中，请稍候......" >
       <a-form-model size="small" layout="inline" label-width="68px">
         <a-form-model-item label="样品类型" prop="sampleType">
           <a-select v-model="typeValue" placeholder="请选择样品类型" show-search :allowClear="true">
             <a-select-option key="0" value="0">条纹相</a-select-option>
             <a-select-option key="1" value="1">三氧化二铝棒状</a-select-option>
             <a-select-option key="2" value="2">三氧化二铝孔隙率</a-select-option>
           </a-select>
         </a-form-model-item>
         <a-form-model-item label="催化剂类型：" prop="sampleNumberArr">
           <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">
             <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}
             </a-select-option>
           </a-select>
         </a-form-model-item>
         <a-form-model-item label="样品编号：" prop="sampleNumberArr">
           <a-select style="width:200px;" placeholder="请选择样品编号" mode="multiple" :maxTagCount="1" @change="handleChange"
                     @dropdownVisibleChange="handleDropdownVisibleChange">
             <a-select-option v-for="item in files" :key="item.id" :value="item.id" :title="item.value">{{ item.value }}
             </a-select-option>
           </a-select>
         </a-form-model-item>
         <a-form-model-item label="识别信息：" prop="sampleNumberArr">
           <a-select v-model:value="resultValue" style="width:320px;" placeholder="请选择实验信息" show-search :allowClear="true">
             <a-select-option v-for="item in resultInfos" :key="item.id" :value="item.id" :title="item.value">{{ item.value }}
             </a-select-option>
           </a-select>
         </a-form-model-item>
         <a-form-model-item>
           <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">查询</a-button>
         </a-form-model-item>
       </a-form-model>

<!--       <div style="">-->
<!--        <span>催化剂类型：</span>-->
<!--        <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">-->
<!--          <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--        <span style="margin: 0 10px">样品编号：</span>-->
<!--        <a-select style="width:200px;" placeholder="请选择样品编号" mode="multiple" :maxTagCount="1" @change="handleChange"-->
<!--                  @dropdownVisibleChange="handleDropdownVisibleChange">-->
<!--          <a-select-option v-for="item in files" :key="item.id" :value="item.id" :title="item.id">{{ item.value }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--        <span style="padding-left: 20px">识别信息：</span>-->
<!--        <a-select v-model:value="resultValue" style="width:320px;" placeholder="请选择实验信息" show-search :allowClear="true">-->
<!--          <a-select-option v-for="item in resultInfos" :key="item.id" :value="item.id" :title="item.id">{{ item.value }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--        <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">查询</a-button>-->
<!--      </div>-->
      <image-list ref="eleImageList" :image-files="imageFiles" :full-width="dialogWidth" :show-arrow-button="false"
                  :allow-click-image="true" />
     </a-spin>
    </div>
  </a-modal>
</template>

<script>
import {Modal} from 'ant-design-vue';
import {getFilesData,getImageFiles, newReport,getCatalystsData,getResultInfoData} from "../utils/data-manager";
import ImageList from './ImageListMul'

export default {
  name: "FolderDivideDialog",
  components: {
    ImageList
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "报告管理"
    }
  },
  watch:{
    showModal(value){
      if(value){
        this.showImage=false;
        this.fileValue=null;
        this.imageFiles=[];
      }
    }
  },
  data() {
    return {
      catalysts: [],
      catalystValue: null,
      loading:false,
      showImage: false,
      fileValue: null,
      resultInfos: [],
      resultValue: null,
      typeValue:null,
      imageFiles: [],
      dialogWidth: 1000,
      files: [],
    }
  },
  async created() {
    this.catalysts = await getCatalystsData();
    this.resultInfos = await getResultInfoData();
  },
  methods: {
    async search() {
      if(this.fileValue&&this.fileValue.length>0) {
        this.loading = true;
        this.imageFiles = await getImageFiles(null, null, null, this.fileValue,this.resultValue,this.typeValue);
        this.loading = false;
        this.showImage = true;
      }else{
        Modal.info({
          title: "提示",
          content: "必须选择样品编号！",
          centered: true
        });
      }
    },
    async save(){
      let imageIds = this.$refs.eleImageList.getImageIds();
      if(imageIds.length>0){
        let data=await newReport(imageIds.join(),this.typeValue);
        if(data){
          if(data.flag){
            this.close();
            this.$parent.search();
          }else{
            let infoTitle="操作失败";
            Modal.info({
              title:infoTitle,
              content: data.msg,
              centered:true
            });
          }
        }
      }else{
        Modal.info({
          title:"提示信息",
          content:"请先选择报告所包含的图片文件！",
          centered:true,
        });
      }
    },
    close() {
      this.handleCancel();
    },
    handleCancel() {
      this.$parent.closeFolderDivide();
    },
    handleChange(value) {
      this.fileValue = value;
    },
    async handleDropdownVisibleChange(open){
      if(open){
        this.files = await getFilesData(this.catalystValue,null,null,this.typeValue);
      }
    },
  }
}
</script>

<style scoped>
.main {
  border: none;
  background: white;
  margin-top: 8px;
}

/deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .ant-modal-body {
  padding: 0px;
}

/deep/ .ant-row {
  margin: 0px;
  padding: 0px;
}
.ant-modal-footer{
  border-top: none!important;
}
</style>
